/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:31:06
 * @LastEditors: dingxuejin
 */
import {PureComponent as Component} from "react";
import ItemGroup from '../../components/ItemGroup'
import Item from '../../components/Item'
import AllBtn from '../../components/AllBtn'
import css from './index.module.scss'
import infoService from '../../../../api/service/info'
import {parseTime} from '../../../../utils/methods';
import { Spin } from 'antd';
import {setOssUrl} from "@/utils/methods/_common"

function handleData(list) {
  return (list || []).map(item => ({
    id: item.fid,
    title: item.ftitle,
    img: setOssUrl(item.fmainpic),
    time: parseTime(item.fsubmittime, 'YYYY/MM/DD'),
    content: item.fabstract,
    videoUrl: setOssUrl(item.fvideo),
  }))
}

class VideosCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      isLoading: true
    };
  }

  componentDidMount() {
    this.getFirstList();
  }

  getFirstList = () => {
    infoService.videoList({pageNo: 1, pageSize: 6}).then(res => {
      this.setState({
        list: handleData(res.list),
        total: res.total
      });
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  getAllList = () => {
    this.setState({isLoading: true});
    infoService.videoList().then(res => {
      this.setState({
        list: handleData(res.list)
      });
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  render() {
    let {total, list = [], isLoading} = this.state;
    return (
        <div className={css["dj-videos-center"]}>
          <div className={css['dj-videos-center-content']}>
            <ItemGroup layout={[[32,32,32]]} colGap="30px" model="loop">
              {this.state.list.map((item, index)=>(<Item key={index} item={item} type="video"></Item>))}
            </ItemGroup>
            {
              isLoading && (
                  <div className={css['loading']}>
                    <Spin />
                  </div>
              ) || ''
            }
            {
              list.length < total && !isLoading && (
                  <div className={css['all-btn']}>
                    <AllBtn onClick={this.getAllList}></AllBtn>
                  </div>
              ) || ''
            }
          </div>
        </div>
    );
  }
}

export default VideosCenter;
